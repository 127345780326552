import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import { StaticImage } from 'gatsby-plugin-image'

import Scrollspy from 'react-scrollspy'
import Scroll from '../../components/Scroll'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'
import Nav from '../../components/Nav'

import { Link } from 'gatsby'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import apple_budge from '../../assets/images/App_Store_Badge_US-UK_135x40.png'
import otapri02 from '../../assets/images/otapri/otapri_sc2.jpg'
import otapri03 from '../../assets/images/otapri/otapri_sc3.jpg'
import otapri04 from '../../assets/images/otapri/otapri_sc4.jpg'
import otapri05 from '../../assets/images/otapri/otapri_sc5.jpg'

const Otapri = ({ data, location }) => {
  const [stickyNav, setStickyNav] = useState(false)
  const handleWaypointEnter = () => {
    setStickyNav(false)
  }
  const handleWaypointLeave = () => {
    setStickyNav(true)
  }

  const otapri_main = '../../assets/images/otapri/otapri_main.jpg'

  return (
    <Layout>
      <Helmet title="おたのプリンセス！ (おたプリ)" />
      <HeaderGeneric />
      <Waypoint
        onEnter={handleWaypointEnter}
        onLeave={handleWaypointLeave}
      ></Waypoint>
      <Nav sticky={stickyNav} />

      <div id="main">
        <section id="top" className="main special">
          <header className="major">
              <h2>おたのプリンセス！ (おたプリ)</h2>
          </header>
          <span className="image major aligncenter"><StaticImage src={otapri_main} alt="" width="480" /></span>
        </section>
        <section id="index" className="main">
          <Scrollspy items={ ['download', 'movie', 'content', 'sc', 'colum'] } currentClassName="is-active" offset={-300}></Scrollspy>
          <ul>
            <li>
              <Scroll type="id" element="download">
                  <a href="#download">ダウンロード</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="movie">
                <a href="#movie">ムービー</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="content">
                <a href="#content">内容</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="sc">
                <a href="#sc">スクリーンショット</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="colum">
                <a href="#colum">コラム</a>
              </Scroll>
            </li>
          </ul>
        </section>
        <section id="download" className="main">
          <header className="major">
            <h2>ダウンロード</h2>
          </header>
          <div className="horizon-budges">
            <a href="https://itunes.apple.com/jp/app/id1088148448?mt=8" target="_blank" rel="noopener noreferrer" className="icon"><img className="apple" src={apple_budge} alt="" /></a>
            <a href="https://play.google.com/store/apps/details?id=net.cretia.OtanoPrincess" target="_blank" rel="noopener noreferrer" className="icon"><img className="google" src="https://play.google.com/intl/ja/badges/images/generic/en_badge_web_generic.png" alt="Get it on Google Play" width="155" height="60" /></a>
          </div>
        </section>
        <section id="movie" className="main">
          <header className="major">
            <h2>ムービー</h2>
          </header>
          <div className="video-player">
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/KpLcoMX6408?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
          </div>
        </section>
        <section id="content" className="main">
          <header className="major">
            <h2>内容</h2>
          </header>
          <h3>こうして、僕の初恋は彼女(オタサーの姫)に奪われた――</h3>
          <p>ゲームを作るオタクが集まる、通称”ゲー研”<br />
          そして、そこに突如入部してきた”オタサーの姫”</p>
          <p>彼女を中心に巻き起こる大波乱に、ゲー研は何度もピンチに……！？</p>
          <p>オタクたちのドタバタ青春ラブコメディ！</p>

          <h3>◆ 楽しく、そして濃厚なストーリー</h3>
          <p>「ノベルパート」では、ゲー研の男子メンバーとオタサーの姫こと”ヒメコ”を中心とした、ドタバタストーリーをお楽しみいただけます。</p>
          <p>この学校に伝わる病とは？<br />
          ヒメコの過去とは？</p>
          <p>あなたの選択で、ゲー研の運命は変わる！</p>

          <h3>◆ メンバーにタスクを割り振ってゲーム開発</h3>
          <p>「ゲーム開発パート」では、部長兼プロジェクトリーダーとなって、チームメンバーにタスクを割り振りましょう。<br />
          全員がノルマを達成することで、次のストーリーを見ることができます。<br />
          ゲームを無事に最後まで完成させ、ゲームコンテストで優勝することがプレイヤーの目標です。</p>

          <hr />

          <table>
            <colgroup><col span="1" /></colgroup>
            <tbody>
              <tr><th>タイトル</th><td>おたのプリンセス！ ～ゲー研とオタサーの姫～</td></tr>
              <tr><th>プラットフォーム</th><td>iOS 8.1 以上 / Android 4.1 以上</td></tr>
              <tr><th>ジャンル</th><td>ノベル×放置ゲーム</td></tr>
              <tr><th>提供形態</th><td>無料ゲーム(広告、課金要素あり。無料でフルコンプリート可能です)</td></tr>
              <tr><th>リリース日</th><td>2016年 5月 6日</td></tr>
            </tbody>
          </table>
        </section>
        <section id="sc" className="main">
          <header className="major">
            <h2>スクリーンショット</h2>
          </header>
          <Carousel className="carousel-custom" width={340} showArrows={true} showThumbs={true} showIndicators={false}>
              <div>
                  <img src={otapri02} alt="" />
              </div>
              <div>
                  <img src={otapri03} alt="" />
              </div>
              <div>
                  <img src={otapri04} alt="" />
              </div>
              <div>
                  <img src={otapri05} alt="" />
              </div>
          </Carousel>
        </section>
        <section id="colum" className="main">
          <header className="major">
            <h2>コラム</h2>
          </header>
          <ul>
            <li><Link to="/blog/20160507-otapri">『おたのプリンセス！』(おたプリ)をリリースしました！</Link></li>
            <li><a href="http://blog.mokosoft.com/entry/2016/05/27/084536" target="_blank" rel="noopener noreferrer">[開発者インタビュー] 勝手に個人アプリ開発者インタビュー第１回！「おたのプリンセス！」刻猫さん</a></li>
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export default Otapri
